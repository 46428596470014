<template>
  <div class="wiki-events-page">
    <div class="limited_content" v-if="wikiArticleLoadingState">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <div class="limited_content" v-else>
      <article-content :article="wikiArticleState"></article-content>
    </div>
  </div>
</template>

<script>
import ArticleContent from '@common/pages/wiki/article/ArticleContent';
import {mapGetters} from 'vuex';

export default {
  metaInfo() {
    return {
      title: 'Events',
    };
  },
  created() {
    this.$store.dispatch('fetchWikiArticleAction', 'mcr-events');
  },
  computed: {
    ...mapGetters(['wikiArticleState', 'wikiArticleLoadingState']),
  },
  components: {ArticleContent},
};
</script>

<style lang="scss" scoped></style>
