<template>
  <div class="wiki-category-page">
    <wiki-header :steps="headerSteps"></wiki-header>

    <div class="limited_content" v-if="!wikiCategoryState || wikiCategoryLoadingState">
      <div v-if="notFound">
        Category not found. Please try searching for a keyword.
        <cta-block-compare-plans></cta-block-compare-plans>
      </div>
      <wiki-loading-category v-else></wiki-loading-category>
    </div>
    <div class="limited_content" v-else>
      <wiki-admin :article="wikiCategoryState.details_article"></wiki-admin>
      <article-content :article="wikiCategoryState.details_article"></article-content>

      <template v-if="wikiCategoryState.child_categories.length">
        <h5>Sub-categories</h5>
        <ul class="sub-categories">
          <li v-for="category in wikiCategoryState.child_categories" :key="category.slug">
            <router-link :to="{name: 'wiki-category', params: {slug: category.slug}}">{{ category.title }}</router-link>
          </li>
        </ul>
      </template>

      <template v-if="wikiCategoryState.articles.length">
        <h5>Articles</h5>
        <ul class="articles">
          <li v-for="article in sortedArticles" :key="article.slug">
            <router-link :to="{name: 'wiki-article', params: {slug: article.slug}}">{{ article.title }}</router-link>
          </li>
        </ul>
      </template>

      <div class="clear-div"></div>
      <cta-block-compare-plans></cta-block-compare-plans>
    </div>

    <end-actions>
      <template v-if="wikiCategoryState">
        Was this article helpful? <a href="mailto:feedback@mychinaroots.com" target="_blank">Suggest an improvement</a>
      </template>
    </end-actions>
    <go-up-button></go-up-button>
  </div>
</template>

<script>
import GoUpButton from '@common/elements/buttons/GoUpButton';
import EndActions from '@common/elements/buttons/endActions';
import WikiHeader from '@common/pages/wiki/WikiHeader';
import WikiLoadingCategory from '@common/pages/wiki/WikiLoadingCategory';
import WikiAdmin from '@common/pages/wiki/admin/WikiAdmin';
import ArticleContent from '@common/pages/wiki/article/ArticleContent';
import wikiCategory from '@common/pages/wiki/js/wikiCategory';
import sortBy from 'lodash/sortBy';
import {mapGetters} from 'vuex';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans';

export default {
  metaInfo() {
    return wikiCategory.getMetaInfo(this);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevStep = wikiCategory.getPrevStep(from, to, vm);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.prevStep = wikiCategory.getPrevStep(from, to, this);
    next();
  },
  created() {
    if (this.wikiCategoryState && this.wikiCategoryState.details_article.slug === this.$route.params.slug) {
      return;
    }
    this.init();
  },
  watch: {
    '$route.params': {
      handler: function (toParams, fromParams) {
        if (toParams.slug !== fromParams.slug) {
          this.$scrollTo({x: 0, y: 0});
          this.init();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      fetchRan: false,
      prevStep: null,
    };
  },
  computed: {
    ...mapGetters(['wikiCategoryState', 'wikiCategoryLoadingState']),
    headerSteps() {
      return wikiCategory.getHeaderSteps(this);
    },
    notFound() {
      return this.fetchRan && !this.wikiCategoryState;
    },
    childCategoriesSlugs() {
      return this.wikiCategoryState.child_categories ? this.wikiCategoryState.child_categories.map(c => c.slug) : [];
    },
    sortedArticles() {
      return sortBy(this.wikiCategoryState.articles, 'title');
    },
  },
  methods: {
    init() {
      wikiCategory.init(this);
    },
  },
  components: {
    CtaBlockComparePlans,
    ArticleContent,
    EndActions,
    WikiHeader,
    GoUpButton,
    WikiAdmin,
    WikiLoadingCategory,
  },
};
</script>

<style scoped lang="scss">
@import '~@common/pages/wiki/styles/details.scss';
</style>
